import React from 'react'
import name2 from '../assets/imgs/name2.png'
import { motion, AnimatePresence } from 'framer-motion';

export const Loadscreen = ({ loading }) => {
    return (
        <AnimatePresence>
            {loading &&
                <motion.div className='loadingscreen gridbg' key="modal"
                    initial={{ opacity: 1, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 1, y: "-100%", transition: { type: 'spring', duration: 4 } }}

                >
                    <img src={name2} className="name" alt='skull' />
                    <span className="loader"></span>

                </motion.div>
            }

        </AnimatePresence>

    )
}
