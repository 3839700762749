import React from 'react'
import pilltube from '../assets/imgs/pilltube.png'
import p1 from '../assets/imgs/nav/p1.png'
import p2 from '../assets/imgs/nav/p2.png'
import p3 from '../assets/imgs/nav/p3.png'

export const Navbar = () => {
    return (
        <div className='navbar'>
            <img src={pilltube} alt="pilltube" className='pilltube' />
            <div className="right">
                <a href="https://twitter.com/catwifcrack"><img src={p1} alt="" className="navicon" /></a>
                <a href="https://t.co/yaoGJRwltw"><img src={p2} alt="" className="navicon" /></a>
                <a href="https://dexscreener.com/solana/CgANeFLtUEdAe6zC2vicWkq6516iNDa8AqgQmtPm5Q9K"><img src={p3} alt="" className="navicon" /></a>

            </div>
        </div>
    )
}

