import { useState, useEffect } from "react";
import { Navbar } from "./components/Navbar";
import { Toparea } from "./components/Toparea";
import { Loadscreen } from "./components/Loadscreen";

function App() {


  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000)
  }, [])


  return (
    <div className="App">

      <Loadscreen loading={loading} />

      <Navbar />
      <Toparea />


    </div>
  );
}

export default App;
