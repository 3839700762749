import React, { useState, useEffect } from 'react'
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import atop from '../assets/imgs/atop.png'

import h1 from '../assets/imgs/h1.png'
import catfly from '../assets/imgs/catfly.png'
import catpoint from '../assets/imgs/catpoint.png'
import circ1 from '../assets/imgs/circ1.png'
import circ2 from '../assets/imgs/circ2.png'
import buybtn from '../assets/imgs/buybtn.png'

import bricks from '../assets/imgs/bricks.png'


import eye1 from '../assets/imgs/eye1.png'
import name2 from '../assets/imgs/name2.png'
import gif1 from '../assets/imgs/vids/video.gif'
import crac from '../assets/imgs/crac.png'
import logo from '../assets/imgs/logo.png'
import abtname from '../assets/imgs/abt.png'

import c1 from '../assets/imgs/c1.png'
import c2 from '../assets/imgs/c2.png'
import c3 from '../assets/imgs/c3.png'
import bigcat from '../assets/imgs/bigcat.png'
import vid1 from '../assets/imgs/vids/1.mp4'
import vid2 from '../assets/imgs/vids/2.mp4'
import vid3 from '../assets/imgs/vids/3.mp4'
import jada from '../assets/imgs/jada.png'
import will from '../assets/imgs/will.png'
import followtxt from '../assets/imgs/followtxt.png'

import p1 from '../assets/imgs/nav/p1.png'
import p2 from '../assets/imgs/nav/p2.png'
import p3 from '../assets/imgs/nav/p3.png'




export const Toparea = () => {

    const [scrollY, setScrollY] = useState(0);
    const controls = useAnimation();

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const imageX = scrollY * 4.5;
        // Calculate rotation based on scrollY, oscillating back and forth
        const rotation = Math.sin(scrollY / 10) * 100; // Oscillates between -10 and 10 degrees

        controls.start({
            x: imageX,
            rotate: rotation,
            transition: { type: 'spring', stiffness: 100 },
        });
    }, [scrollY, controls]);








    const cloudVariants = {
        drive: {


            y: ['0%', '500%', '0%'],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "linear"
                },
                y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 2,
                    ease: "linear"
                }
            }
        }
    };



    const [currentVid, setCurrentVid] = useState("one")
    const [activeImage, setActiveImage] = useState('one');

    // Function to set the current video and mark the image as active
    const handleHoverStart = (imageId) => {
        setCurrentVid(imageId); // Assuming setCurrentVid is defined elsewhere to update some state
        setActiveImage(imageId);
    };

    // Helper function to determine the className based on active state
    const getClassName = (imageId) => `c${imageId} ${activeImage === imageId ? 'active' : 'inactive'}`;











    const containerVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { duration: 0.5 } },
        exit: { scale: 0, opacity: 0, transition: { duration: 0.5 } }
    };



    return (
        <>
            <div className='toparea'>
                <img src={atop} alt="" className='atop' />

                <motion.div className="flyslide"
                >
                    <motion.img src={catfly} alt="" className='catfly' initial={{ x: 0 }}
                        animate={controls} />
                </motion.div>

                <div className="centercirc">
                    <div className="circ1">
                        <motion.img src={circ1} alt="circ1img" className="circ1img" animate={{ rotate: 360 }}
                            transition={{ duration: 7, repeat: Infinity, ease: 'linear' }} />


                    </div>
                    <div className="headarea">
                        <img src={h1} alt="" className='head' />
                    </div>

                    <div className="circ2">
                        <motion.img src={circ2} alt="circ2" className="circ2img" animate={{ rotate: -360 }}
                            transition={{ duration: 4, repeat: Infinity, ease: 'linear' }} />
                    </div>
                </div>
                <a href="https://raydium.io/swap/?outputCurrency=CgANeFLtUEdAe6zC2vicWkq6516iNDa8AqgQmtPm5Q9K">
                    <motion.img src={buybtn} alt="buybtn" className="buybtn" whileHover={{ rotate: 720 }}
                        transition={{ duration: 1, repeat: Infinity, repeatType: "mirror" }}
                    />
                </a>

                <motion.img src={catpoint} alt="catpoint" className="catpoint" animate={{
                    rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                }}
                    transition={{
                        duration: 0.5, // Duration of one cycle of rotation
                        ease: "linear", // Linear easing for constant speed
                        repeat: Infinity, // Repeat the animation infinitely
                        repeatType: "loop", // Ensure the animation loops from start to end
                    }} />

            </div>







            <div className="midarea">
                <img src={bricks} alt="" className='bricks1' />
                <img src={bricks} alt="" className='bricks2' />


                <div className="eyearea eyearea1">
                    <motion.img src={eye1} alt="eye" className='eye1' animate={{
                        rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.1, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} />
                </div>

                <div className="eyearea eyearea1">
                    <motion.img src={eye1} alt="eye" className='eye1' animate={{
                        rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.1, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} />
                </div>

                <div className="eyearea eyearea2">
                    <motion.img src={eye1} alt="eye" className='eye1' animate={{
                        rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.1, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} />
                </div>

                <div className="eyearea eyearea3">
                    <motion.img src={eye1} alt="eye" className='eye1' animate={{
                        rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.1, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} />
                </div>

                <div className="eyearea eyearea4">
                    <motion.img src={eye1} alt="eye" className='eye1' animate={{
                        rotate: [0, 10, -10, 0], // Degrees to rotate to, back and forth
                    }}
                        transition={{
                            duration: 0.1, // Duration of one cycle of rotation
                            ease: "linear", // Linear easing for constant speed
                            repeat: Infinity, // Repeat the animation infinitely
                            repeatType: "loop", // Ensure the animation loops from start to end
                        }} />
                </div>
                <motion.img src={name2} alt="nameg" className='name2'
                    animate={{
                        scale: [1, 0.85, 0.85, 1], // Sequence: start at normal size, shrink, expand, return to normal
                    }}
                    transition={{
                        duration: 1, // Total duration for the sequence
                        ease: "easeInOut", // Easing function for a smooth effect
                        repeat: Infinity, // Repeat the animation infinitely
                        repeatType: "loop", // Ensure the animation loops smoothly
                    }}

                />
                <img src={gif1} className='gif1' playsInline autoPlay />

                <div className="glassarea dh">
                    <div className="txt white">
                        <ul>
                            <li>Name : CAT WIF CRACK</li>
                            <li>Ticker : $CRACK </li>
                            <li>Supply : 100 mil</li>
                            <li>Taxes : 0/0</li>
                            <li>LP Status : Burnt</li>
                            <li>Powered by Crackheads</li>
                        </ul>
                    </div>
                    <img src={abtname} alt="" className="abtname" />

                    <img src={crac} alt="" className="crac" />
                    <img src={logo} alt="" className="logo" />
                </div>
            </div>








            <div className="bottomarea">
                {/* <div className="eyearea">
                    <img src="" alt="eye2" className='eye2' />
                </div> */}
                {/* <img src={eyesline} alt="eyesline" className='eyesline' /> */}

                <motion.div className="downslider" variants={cloudVariants} animate='drive' style={{
                    position: 'absolute',
                    top: 'initial Y position',
                    left: 'initial X position',
                }} >
                    <img src={bigcat} alt="wildcat" className='bigcat' />
                </motion.div>

                <img src={jada} alt="" className="jada" />
                <img src={will} alt="" className="will" />
                <motion.img src={crac} alt="" className="crac2" animate={{
                    rotate: [0, 180, -180, 0], // Degrees to rotate to, back and forth
                }}
                    transition={{
                        duration: 3, // Duration of one cycle of rotation
                        ease: "linear", // Linear easing for constant speed
                        repeat: Infinity, // Repeat the animation infinitely
                        repeatType: "loop", // Ensure the animation loops from start to end
                    }} />

                <div className="centerarea">
                    <div className="topthree">
                        <motion.img src={c1} alt="" className={getClassName('one')}
                            onHoverStart={() => handleHoverStart("one")}
                            onClick={() => handleHoverStart("one")}
                        />
                        <motion.img src={c2} alt="" className={getClassName('two')}
                            onHoverStart={() => handleHoverStart("two")}
                            onClick={() => handleHoverStart("two")}

                        />
                        <motion.img src={c3} alt="" className={getClassName('three')}
                            onHoverStart={() => handleHoverStart("three")}
                            onClick={() => handleHoverStart("three")}

                        />
                    </div>


                    <div className="vidcnt">
                        <img src={followtxt} alt="" className='followtxt' />
                        {currentVid === "one" && (
                            <motion.video
                                src={vid1}
                                className='video'
                                playsInline
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={containerVariants}
                                controls
                            />
                        )}
                        {currentVid === "two" && (
                            <motion.video
                                src={vid2}
                                className='video'
                                playsInline
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={containerVariants}
                                controls
                            />
                        )}
                        {currentVid === "three" && (
                            <motion.video
                                src={vid3}
                                className='video'
                                playsInline
                                autoPlay={false}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                variants={containerVariants}
                                controls
                            />
                        )}
                    </div>

                </div>

            </div>
            <div className="footer">
                <img src={bricks} alt="" className='bricks3' />

                <div className="left">
                    <a href="https://twitter.com/catwifcrack"><img src={p1} alt="" className="navicon" /></a>
                    <a href="https://t.co/yaoGJRwltw"><img src={p2} alt="" className="navicon" /></a>
                    <a href="https://dexscreener.com/solana/CgANeFLtUEdAe6zC2vicWkq6516iNDa8AqgQmtPm5Q9K"><img src={p3} alt="" className="navicon" /></a>
                </div>

                <div className="dh white textft"> Cat Wif Crack On Solana - 2024 - all rights reserved.<br></br>
                    This is a memecoin: buy if you love crack, but dont expect any money out of it.
                </div>
                <img src={logo} alt="" className='footerlogo' />
            </div>
        </>
    )
}

